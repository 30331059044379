@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.profile {
  padding: 1.25rem;
  min-height: 100vh;

  @include tablet {
    padding: 2.5rem 2.5rem;
  }
  @include desktop {
    padding: 2.5rem 6.25rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: baseline;

    @include tablet {
      gap: 10%;
    }

    @include desktop {
      gap: 5%;
    }
  }

  &__list-item {
    border: 3px solid $primary;
    margin: 1.25rem 0;
    padding: 0.75rem;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    @include tablet {
      width: 45%;
    }

    @include desktop {
      width: 30%;
    }
  }

  &__date {
    background-color: $primary;
    margin: -0.75rem -0.75rem;
    margin-bottom: 0.75rem;
    padding: 0.75rem 0.75rem 1.25rem 0.75rem;
    font-size: 1.5625rem;
  }

  &__delete-button {
    background-color: $primary-dark;
    color: $white;
    padding: 0.625rem;
    margin: 0.75rem 0;
    margin-right: 0.75rem;
    border-radius: 0.625rem;
    border: 1px solid $primary-dark;
    transition: 100ms ease-in-out;
    float: right;

    &:hover {
      background-color: $white;
      border: 1px solid $primary;
      color: $black;
    }
  }

  &__exercise-count {
    color: $primary-dark;
    font-size: 1.875rem;
  }

  &__hr {
    border-color: $primary-medium;
    margin: 0.75rem -0.75rem;
  }

  &__subhead {
    display: inline;
  }

  &__data {
    display: inline;
    color: $primary-dark;
  }

  &__search {
    width: 100%;
    height: 2rem;
    border-radius: 0.625rem;
    padding-left: 0.75rem;
    border: 2px solid $primary-medium;
    margin-bottom: 0.75rem;
    background-image: url(../../assets/images/search.svg);
    background-repeat: no-repeat;
    padding-left: 2.1875rem;
    background-position: 0.3125rem 50%;
    background-size: 1.25rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    @include tablet {
      width: 45%;
    }

    @include desktop {
      width: 30%;
    }
    &:focus {
      border-color: $primary;
    }
  }
}
