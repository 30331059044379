@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.header {
  background-image: linear-gradient(to right, $primary-dark, $primary-medium);
  padding: 1.25rem 1.25rem 2.1875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  @include tablet {
    flex-direction: row;
    padding: 1.25rem 2.5rem;
  }

  @include desktop {
    flex-direction: row;
    padding: 1.25rem 6.25rem;
  }

  &__logo {
    height: 3.75rem;
    margin-bottom: 1.25rem;

    @include tablet {
      margin-bottom: 0;
    }

    @include desktop {
      margin-bottom: 0;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__nav-item {
    background-color: $white;
    padding: 0.625rem;
    margin: 0 0.625rem;
    border-radius: 0.625rem;
    border: none;
    transition: 100ms ease-in-out;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: $primary;
    }
  }
}
