@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.login {
  padding: 20px;
  min-height: 100vh;

  @include tablet {
    padding: 40px 40px;
  }
  @include desktop {
    padding: 40px 100px;
  }

  &__submit {
    background-color: $primary-dark;
    color: $white;
    padding: 10px;
    margin: 12px 0;
    margin-right: 12px;
    border-radius: 10px;
    border: 1px solid $primary-dark;
    transition: 100ms ease-in-out;
    display: block;

    &:hover {
      background-color: $white;
      border: 1px solid $primary;
      color: $black;
    }
  }

  &__form {
    @include tablet {
      width: 50%;
    }

    @include desktop {
      width: 30%;
    }
  }
}
