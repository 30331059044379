* {
  font-family: "Alice", sans-serif;
}

@font-face {
  font-family: "Alice";
  src: url("../../assets/fonts/Alice-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

