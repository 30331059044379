@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.homepage {
  padding: 1.25rem;
  min-height: 100vh;

  @include tablet {
    padding: 2.5rem 2.5rem;
  }
  @include desktop {
    padding: 2.5rem 6.25rem;
  }

  &__text {
    margin: 1.875rem 0;
  }
  &__list {
    margin: 2.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include tablet {
      width: 50%;
    }

    @include desktop {
      width: 50%;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 3.125rem;
    margin-right: 0.75rem;
  }

  &__list-text {
    margin-bottom: 0;
  }

  &__image {
    width: 100%;
    border-radius: 0.625rem;
    margin-bottom: 1.25rem;
  }
}
