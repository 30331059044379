@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.about {
  padding: 1.25rem;
  min-height: 100vh;

  @include tablet {
    padding: 2.5rem 2.5rem;
  }
  @include desktop {
    padding: 2.5rem 6.25rem;
  }

  &__text {
    margin: 1.25rem 0;
  }
}
