@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.badges {
  border: 3px solid $primary-medium;
  padding: 0.75rem;
  margin: 1.25rem 0;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  &__badge {
    height: 6.25rem;
    margin: 0.75rem;
  }

  &__header {
    background-color: $primary-medium;
    margin: -0.75rem -0.75rem;
    margin-bottom: 0.75rem;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    font-size: 1.5625rem;
    width: 120%;
  }
}
