@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.register {
  padding: 1.25rem;

  @include tablet {
    padding: 2.5rem 2.5rem;
  }
  @include desktop {
    padding: 2.5rem 6.25rem;
  }

  &__submit {
    background-color: $primary-dark;
    color: $white;
    padding: 0.625rem;
    margin: 0.75rem 0;
    margin-right: 0.75rem;
    border-radius: 10px;
    border: 1px solid $primary-dark;
    transition: 100ms ease-in-out;

    &:hover {
      background-color: $white;
      border: 1px solid $primary;
      color: $black;
    }
  }

  &__form {
    @include tablet {
      width: 50%;
    }

    @include desktop {
      width: 30%;
    }
  }
}
