@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.footer {
  background-image: linear-gradient(to right, $primary-dark, $primary-medium);
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: sticky;
  bottom: 0;

  &__coded-by {
    color: white;
    font-size: 0.75rem;
  }

  &__link {
    color: $primary;

    &:visited {
      color: $primary;
    }
  }
}
