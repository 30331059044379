@use "./colors" as *;
@use "./fonts" as *;
@use "./mixins" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

h1 {
  font-size: 1.875rem;
  margin-bottom: 0.75rem;
}

h2 {
  margin-bottom: 0.75rem;
  line-height: 1.75rem;
}

h3 {
  margin: 0.5rem 0;
}

p {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

a {
  text-decoration: none;
  font-weight: 900;
  color: $primary-dark;
}

a:visited {
  color: $primary-dark;
}

.form-input {
  width: 100%;
  height: 2rem;
  border-radius: 0.625rem;
  padding-left: 0.75rem;
  border: 2px solid $primary;
  margin-bottom: 0.75rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  &:focus {
    border-color: $primary;
  }
}

.form-input--error-state {
  border-color: red;
}
