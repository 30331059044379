@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/global" as *;

.exercise {
  padding: 1.25rem;
  min-height: 100vh;

  @include tablet {
    padding: 2.5rem 2.5rem;
  }
  @include desktop {
    padding: 2.5rem 6.25rem;
  }

  &__intro {
    margin: 1.25rem 0;
  }
}

.form {
  border: 3px solid $primary-medium;
  border-radius: 10px;
  padding: 0.75rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  @include tablet {
    width: 80%;
    margin: auto;
  }

  @include desktop {
    width: 50%;
    margin: auto;
  }

  &__radial-container {
    display: flex;
  }

  &__radial-item {
    margin-left: 0.25rem;
    margin-right: 0.75rem;
  }

  &__submit-button,
  &__next-button,
  &__back-button {
    background-color: $primary-dark;
    color: $white;
    padding: 0.625rem;
    margin: 0.75rem 0;
    margin-right: 0.75rem;
    border-radius: 0.625rem;
    border: 1px solid $primary-dark;
    transition: 100ms ease-in-out;

    &:hover {
      background-color: $white;
      border: 1px solid $primary;
      color: $black;
    }
  }

  &__error-message {
    color: red;
  }
}

.radio {
  width: 1.25rem;
  height: 1.25rem;
  accent-color: $primary-dark;
}
